import store from '@/vuex/store';
import Cookies from 'js-cookie';

const guard = async (from) => {
  if (store.getters.allMenu.includes(from.path)) {
    return true;
  }
  return { path: '404' };
};

const adminRoutes = [
  {
    path: '',
    redirect: () => {
      return Cookies.get('menuPath') || '/home/purpose-statistics';
    },
  },
  {
    path: 'home',
    name: 'dashboard',
    // component: () =>
    //   import(
    //     /* webpackChunkName: "Dashboard" */ '@/view/manager/home/Home.vue'
    //   ),
    children: [
      {
        path: 'purpose-statistics',
        name: 'purpose-statistics',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/dashboard/purpose-statistics/list.vue'
          ),
      },
      {
        path: 'processing-status',
        name: 'processing-status',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/dashboard/processing-status/list.vue'
          ),
      },
      {
        path: 'visa-rate',
        name: 'visa-rate',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/dashboard/visa-rate/list.vue'
          ),
      },
      {
        path: 'visa-period',
        name: 'visa-period',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/dashboard/visa-period/list.vue'
          ),
      },
      {
        path: 'visa-value',
        name: 'visa-value',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/dashboard/visa-value/list.vue'
          ),
      },
      {
        path: 'declare-violations',
        name: 'declare-violations',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/dashboard/declare-violations/list.vue'
          ),
      },
      {
        path: 'temporary-residence-address',
        name: 'temporary-residence-address',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/dashboard/temporary-residence-address/list.vue'
          ),
      },
      {
        path: 'statistic-by-email',
        name: 'statistic-by-email',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/dashboard/statistic-by-email/list.vue'
          ),
      },
      {
        path: 'statistic-temporary-residence-address',
        name: 'statistic-temporary-residence-address',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/dashboard/statistic-temporary-residence-address/list.vue'
          ),
      },
    ],
  },
  {
    path: 'settings',
    name: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'
      ),
    children: [
      {
        path: 'profile-settings',
        name: 'profile-settings',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/profile',
        name: 'set-profile',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
          ),
      },
      {
        path: 'profile-settings/account',
        name: 'set-account',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Account.vue'
          ),
      },
      {
        path: 'profile-settings/password',
        name: 'set-password',
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Password.vue'
          ),
      },
    ],
  },
  {
    path: 'system',
    name: 'system',
    children: [
      {
        path: 'menu',
        name: 'menu-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/menu/Menu.vue'
          ),
      },
      {
        path: 'role',
        name: 'role-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/role/Role.vue'
          ),
      },
      {
        path: 'user',
        name: 'user-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/user/User.vue'
          ),
      },
      {
        path: 'warning',
        name: 'warning-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/warning/list.vue'
          ),
      },
      {
        path: 'log',
        name: 'log-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/log-management/list.vue'
          ),
      },
      {
        path: 'security',
        name: 'security-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/security/Security.vue'
          ),
      },
      {
        path: 'unit-management',
        name: 'unit-management',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/unit-management/list.vue'
          ),
      },
      {
        path: 'ip',
        name: 'ip-management',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/ip/index.vue'
          ),
      },
      {
        path: 'ip/detail',
        name: 'ip-detail',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/ip/detail.vue'
          ),
      },
      {
        path: 'leader',
        name: 'leader',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/leader/list.vue'
          ),
      },
      {
        path: 'sync-profile',
        name: 'sync-profile',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/sync-profile/form.vue'
          ),
      },
      {
        path: 'synchronous-control',
        name: 'synchronous-control',
        component: () =>
            import(
                /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/synchronous-control/SynchronousControl.vue'
                ),
      },
    ],
  },
  {
    path: 'control-management',
    name: 'control-management',
    children: [
      {
        path: 'ngan-luong',
        name: 'ngan-luong',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/system/control-management/list.vue'
          ),
      },
      {
        path: 'search-profile-sync',
        name: 'search-profile-sync',
        beforeEnter: [guard],
        component: () =>
            import(
                /* webpackChunkName: "Manager" */ '@/view/system/search-profiles-sync/SearchProfilesSync.vue'
                ),
      },
    ]
  },
  {
    path: 'qa',
    name: 'qa',
    children: [
      {
        path: 'frequently-asked',
        name: 'frequently-asked',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/qAndA/frequentlyAsked/FrequentlyAsked.vue'
          ),
      },
      {
        path: 'q-and-a',
        name: 'q-and-a',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/qAndA/qAndA/QAndA.vue'
          ),
      },
    ],
  },
  {
    path: 'security',
    name: 'security',
    component: () =>
      import(
        /* webpackChunkName: "Manager" */ '@/view/manager/security/Security.vue'
      ),
  },
  {
    path: 'categories',
    name: 'categories',
    children: [
      {
        path: 'gate',
        name: 'gate',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/gate/Gates.vue'
          ),
      },
      {
        path: 'purpose-of-entry',
        name: 'purpose-of-entry',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/purpose-of-entry/Purposes.vue'
          ),
      },
      {
        path: 'tariff',
        name: 'tariff',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/tariff/Tariffs.vue'
          ),
      },
      {
        path: 'visa-symbol',
        name: 'visa-symbol',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/visa-symbol/VisaSymbols.vue'
          ),
      },
      {
        path: 'passport-type',
        name: 'passport-type',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/passport-type/PassportTypes.vue'
          ),
      },
      {
        path: 'profile-type',
        name: 'profile-type',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/profile-type/ProfileTypes.vue'
          ),
      },
      {
        path: 'international',
        name: 'international',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/international/Internationals.vue'
          ),
      },
      {
        path: 'province',
        name: 'province',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/province/Provinces.vue'
          ),
      },
      {
        path: 'district',
        name: 'district',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/district/Districts.vue'
          ),
      },
      {
        path: 'wards',
        name: 'wards',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/wards/Wards.vue'
          ),
      },
      {
        path: 'unit',
        name: 'unit',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/unit/Units.vue'
          ),
      },
      {
        path: 'exchange-rate',
        name: 'exchange-rate',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/exchange-rate/ExchangeRates.vue'
          ),
      },
      {
        path: 'country-group',
        name: 'country-group',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/categories/country-group/CountryGroup.vue'
          ),
      },
    ],
  },
  {
    path: 'profile',
    name: 'visa-application',
    children: [
      {
        path: 'config-criteria',
        name: 'config-criteria',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/config-criteria-2/FormModal.vue'
          ),
      },
      {
        path: 'new',
        name: 'profile-processing',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/list.vue'
          ),
      },
      {
        path: 'priority',
        name: 'priority',
        beforeEnter: [guard],
        component: () =>
            import(
                /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/PriorityProfile.vue'
                ),
      },
      {
        path: 'new-old',
        name: 'profile-new',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/profile-new/ProfileNews.vue'
          ),
      },
      {
        path: 'file-assignment',
        name: 'file-assignment',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/file-assignment/FileAssignments.vue'
          ),
      },
      {
        path: 'file-approval',
        name: 'file-approval',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/file-approval/FileApprovals.vue'
          ),
      },
      {
        path: 'sign-profile',
        name: 'sign-profile',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/sign-profile/SignProfiles.vue'
          ),
      },
      {
        path: 'visa-cancel',
        name: 'visa-cancel',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/visa-cancel/VisaCancels.vue'
          ),
      },
      {
        path: 'proposed-profile',
        name: 'proposed-profile',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/proposed-profile/ProposedProfiles.vue'
          ),
      },
      {
        path: 'follow-directive',
        name: 'follow-directive',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/follow-directive/FollowDirectives.vue'
          ),
      },
      {
        path: 'additional-reception',
        name: 'additional-reception',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/additional-reception/AdditionalReceptions.vue'
          ),
      },
      {
        path: 'additional-request',
        name: 'additional-request',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/additional-request/AdditionalRequests.vue'
          ),
      },
      {
        path: 'search-info-profile',
        name: 'search-info-profile',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/search-info-profile/SearchInfoProfiles.vue'
          ),
      },
      {
        path: 'watch-profile',
        name: 'watch-profile',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/watch-profile/WatchProfiles.vue'
          ),
      },
      {
        path: 'archived-records',
        name: 'archived-records',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/archived-records/ArchivedRecords.vue'
          ),
      },
      {
        path: 'details',
        name: 'details',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/ProfileDetailsContainer.vue'
          ),
      },
      {
        path: 'multi-object',
        name: 'multi-object',
        component: () =>
            import(
                /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/ProfileDetailsMultiObject.vue'
                ),
      },
      {
        path: 'email-foreigners',
        name: 'email-foreigners',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/profile-processing/email-foreigners/EmailForeigners.vue'
          ),
      },
    ],
  },
  {
    path: 'home-1',
    name: 'home-1',
    children: [
      {
        path: 'article',
        name: 'article',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/article/list.vue'
          ),
      },
      {
        path: 'instruction',
        name: 'instruction',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/instruction/list.vue'
          ),
      },
      {
        path: 'popup',
        name: 'popup',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/popup/list.vue'
          ),
      },
    ],
  },
  {
    path: 'system-htn',
    name: 'system-htn',
    children: [
      {
        path: 'language',
        name: 'language',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/system-htn/language/list.vue'
          ),
      },
      {
        path: 'faq',
        name: 'faq',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/system-htn/faq/list.vue'
          ),
      },
      {
        path: 'user-manual',
        name: 'user-manual',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/system-htn/user-manual/list.vue'
          ),
      },
      {
        path: 'popup',
        name: 'popup',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/system-htn/popup/list.vue'
          ),
      },
      {
        path: 'contact',
        name: 'contact',
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/contact/index.vue'
          ),
      },
      {
        path: 'email',
        name: 'email',
        beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/system-htn/email/list.vue'
          ),
      },
      {
        path: 'posts',
        name: 'posts',
        // beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/system-htn/posts/list.vue'
          ),
      },
      {
        path: 'note-declaration',
        name: 'note-declaration',
        // beforeEnter: [guard],
        component: () =>
          import(
            /* webpackChunkName: "Manager" */ '@/view/manager/system-htn/note-declaration/list.vue'
          ),
      },
      {
        path: 'organization',
        name: 'organization',
        beforeEnter: [guard],
        component: () => import(
         /* webpackChunkName: "Manager" */ '@/view/manager/organization/index.vue'
        ),
      },
    ],
  },
];

export default adminRoutes;
