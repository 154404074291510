export default {
    setLoading(state, loading) {
        state.loading = loading;
    },
    setAll(state, data) {
        state.list = data;
    },
    setData(state, data) {
        state.data = data;
    },
    setListAll(state, data) {
        state.listAll = data.map(item => ({
            ...item,
            tenVaDiaChi: item.tentc + `${item.diaChi ? ' - ' + item.diaChi : ''}`,
        }))
    }
}
